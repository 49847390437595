import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { catchAsync, SESSIONS, successTheme } from "src/helpers";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { loginRequestApi } from "src/services/requests/Auth";
import { ILoginResponse } from "src/services/requests/Auth/modules";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import RoundedButton from "src/shadcn/components/custom/RoundedButton";
import {
  login_initialValues,
  LOGIN_FORM_FIELDS,
  loginFormValidationSchema,
} from "./modules";
import { useAuth } from "./Core";
import { toast } from "sonner";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getTempDataRootState,
  setMobileNoForValidatOtp,
} from "src/redux/reducers/tempDataSlice";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setEmailForValidatOtp,
  setSession,
  setShowLoginModule,
} from "src/redux/reducers/tempDataSlice";

type Props = {
  isOpenDialog?: (e: any) => void;
};

const Login = ({ isOpenDialog }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setCurrentUser, saveAuth } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const { lastUserRoute } = useSelector((state: RootState) =>
    getTempDataRootState(state)
  );

  const formik = useFormik({
    initialValues: login_initialValues,
    validationSchema: loginFormValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      handleLoginForm();
    },
  });

  // console.log({ values: formik.values, error: formik.errors })

  const handleLoginForm = catchAsync(
    async () => {
      setisLoading(true);
      dispatch(setMobileNoForValidatOtp(formik.values.mobileNumber));
      dispatch(setSession(SESSIONS.REGISTRATION));
      return await loginRequestApi(formik.values);
    },

    (result: ILoginResponse) => {
      // console.log(result, '........');

      if (result.data.accessToken) {
        const {
          accessToken,
          userid,
          mobileNumber,
          role,
          firstName,
          lastName,
          _id,
        } = result.data;

        saveAuth({
          api_token: accessToken,
          user_id: _id,
        });

        setCurrentUser({
          mobileNumber: mobileNumber,
          id: _id,
          name: firstName + " " + lastName,
          access_token: accessToken,
          role: role,
          user_id: userid,
        });
        setisLoading(false);
        dispatch(setShowLoginModule(false));
        toast.success("Login Successfully", { style: successTheme });
        if (lastUserRoute) {
          navigate(lastUserRoute);
        } else navigate("/" + MAIN_ROUTES_CONST.marketplace);
        isOpenDialog && isOpenDialog(false);
      }
      // console.log('Login successful:', result);
    },
    ({ status }: any) => {
      setisLoading(false);
      // console.log('Login failed:', status);
      if (status == 400) {
        dispatch(setShowLoginModule(false));
        navigate("/" + MAIN_ROUTES_CONST.verify_otp);
      }
    }
  );

  const handleForgotClick = () => {
    isOpenDialog && isOpenDialog(false);
    dispatch(setShowLoginModule(false));
    navigate(`/${MAIN_ROUTES_CONST.forgot}`);
  };

  return (
    <div className="space-y-4 p-2 h-full">
      {/* <div className=' font-bold uppercase text-primary'>Login</div> */}
      <div className="text-sm">
        If you already have an account, please sign in.
      </div>
      <div>
        <form className="space-y-2" onSubmit={formik.handleSubmit}>
          <div className="space-y-2">
            {LOGIN_FORM_FIELDS.map((field: any) => {
              return (
                <RenderFormComponents
                  key={`login-form-${field.dataKey}`}
                  formik={formik}
                  value={formik.values[field.dataKey as keyof object]}
                  {...field}
                  onChange={(e: any) => formik.setFieldValue(field.dataKey, e)}
                />
              );
            })}
          </div>
          <div className="text-sm text-end text-blue-600">
            <span className="cursor-pointer" onClick={handleForgotClick}>
              Forgot Password
            </span>{" "}
          </div>
          <div>
            <RoundedButton
              disabled={isLoading}
              type="submit"
              className="w-full"
              isLoading={isLoading}
            >
              Login
            </RoundedButton>
          </div>
        </form>
        <Link
          to={process.env.REACT_APP_BASE_URL_USER_MANUAL||""}
          target="_blank"
          className="text-sm  underline underline-offset-4 flex justify-center text-blue-600 mt-2 hover:underline-offset-8"
        >
          User Manual
        </Link>
      </div>
    </div>
  );
};

export default Login;
