import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import {
  catchAsync,
  IS_SUCCESS_RESPONSE,
  SESSIONS,
  successTheme,
} from "src/helpers";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import {
  setEmailForValidatOtp,
  setMobileNoForValidatOtp,
  setSession,
  setShowLoginModule,
} from "src/redux/reducers/tempDataSlice";
import { registrationRequestApi } from "src/services/requests/Auth";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import RoundedButton from "src/shadcn/components/custom/RoundedButton";
import { Checkbox } from "src/shadcn/components/ui";
import {
  REGISTRATION_FORM_FIELDS,
  registration_initialValues,
  registrationFormValidationSchema,
} from "./modules";

type Props = {
  isOpenDialog?: (e: any) => void
};

const Registration = ({ isOpenDialog }: Props) => {


  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { code } = useParams()


  const formik = useFormik({
    initialValues: registration_initialValues,
    validationSchema: registrationFormValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      handleRegistrationForm();
    },
  });


  useEffect(() => {
    if (code) {
      formik.setFieldValue("referralCode", code)
    }

    return () => { }
  }, [code])


  const handleRegistrationForm = catchAsync(
    async () => {
      setisLoading(true);
      console.log({ postData: formik.values });
      return await registrationRequestApi({
        // email: formik.values.email,
        // firstName: formik.values.firstName,
        // lastName: formik.values.lastName,
        mobileNumber: formik.values.mobileNumber?.toString(),
        password: formik.values.password,
        role: formik.values.role,
        // referralCode: formik.values.referralCode
      });
    },
    (result: AxiosResponse) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        dispatch(setMobileNoForValidatOtp(formik.values.mobileNumber));
        dispatch(setSession(SESSIONS.REGISTRATION));
        toast.success("Registration successful! OTP has been sent to your WhatsApp number.", {
          style: successTheme,
        });
        formik.resetForm();
        isOpenDialog && isOpenDialog(false);
        dispatch(setShowLoginModule(false))
        navigate("/" + MAIN_ROUTES_CONST.verify_otp);

      }
      console.log("Registration successful:", result);
      setisLoading(false);
    },
    () => {
      setisLoading(false);
    }
  );

  console.log({ error: formik.errors, values: formik.values });

  return (
    <div className='space-y-4 p-2 h-full overflow-y-scroll no-scrollbar'>
      {/* <div className=" font-bold uppercase text-primary">SignUp</div> */}
      <div className="text-sm">
        Please provide details to get registered
      </div>
      <div className="space-y-2 w-[400px]">
        <form className="space-y-2" onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 gap-4">
            {REGISTRATION_FORM_FIELDS.map((field: any,index:number) => {
              return (
                <RenderFormComponents
                  key={`login-form-${field.dataKey}`}
                  formik={formik}
                  value={formik.values[field.dataKey as keyof object]}
                  {...field}
                  componentProps={{ ...field.componentProps, readonly: code && field.dataKey == "referralCode" ? true : false }}
                  onChange={(e: any) => formik.setFieldValue(field.dataKey, e)}
                  
                />
              );
            })}
          </div>
          <div className="space-x-2 text-sm flex items-center py-2">
            <Checkbox
              checked={formik.values.is_accept}
              onCheckedChange={(e) => formik.setFieldValue("is_accept", e)}
            />
            <span className="text-xs">
              I Accept{" "}

              <button type="button" className="text-blue-600 underline underline-offset-1" onClick={() => {
                dispatch(setShowLoginModule(false))
                navigate("/" + MAIN_ROUTES_CONST.termsAndConditions)
              }}>Terms and Conditions</button>
              {" "}
              and{" "}

              <button type="button" className="text-blue-600 underline underline-offset-1" onClick={() => {
                dispatch(setShowLoginModule(false))
                navigate("/" + MAIN_ROUTES_CONST.dataPrivacy)
              }}>Privacy</button>
              {" "}
            </span>{" "}
          </div>
          <div>
            {formik.errors.is_accept && formik.touched.is_accept && (
              <div className="text-red-500 text-xs">
                {formik.errors.is_accept}
              </div>
            )}
          </div>
          <div>
            <RoundedButton
              isLoading={isLoading}
              disabled={!formik.values.is_accept || isLoading}
              type="submit"
              className="w-full"
            >
              Sign Up
            </RoundedButton>
          </div>
        </form>
        <Link
          to={process.env.REACT_APP_BASE_URL_USER_MANUAL||""}
          target="_blank"
          className="text-sm  underline underline-offset-4 flex justify-center text-blue-600 mt-2 hover:underline-offset-8"
        >
          User Manual
        </Link>
      </div>
    </div>
  );
};

export default Registration;
