import { useNavigate } from 'react-router-dom'
import { cn, ELECTRICITY_BANNER_IMAGE, RESPONSIVE_BANNER_3, RESPONSIVE_BG } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button, CarouselItem } from 'src/shadcn/components/ui'

type Props = {}

const Banner4 = (props: Props) => {

  const navigate = useNavigate()

  return (
    <CarouselItem>
      <div
        className={cn(`w-full xl:h-[650px] h-max relative`)}
        style={{
          background: `url(${RESPONSIVE_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"

        }}
      >
        <div className='flex justify-center xl:justify-end xl:mt-4'>
          <img src={RESPONSIVE_BANNER_3} className='lg:h-[90%] xl:h-[100%] lg:right-0 lg:absolute xl:p-20 pt-24 px-4'/>
        </div>

        <div className=" md:w-[500px]    xl:w-[500px]  mx-auto lg:mx-0   space-y-3 xl:ml-40  pb-10 px-10 w-full">
          <div className='lg:pt-[15vh]'>
            <div className='md:w-[450px]'>
              <img src={ELECTRICITY_BANNER_IMAGE} style={{ height: "100px" }} className='mb-4' />
              <div className='space-y-1'>
                <div className="font-sans font-bold text-3xl md:text-4xl uppercase ">Are you A  residential community</div>
                <div className="font-sans font-bold text-3xl md:text-4xl uppercase ">with limited rooftop space?</div>
              </div>
              <div className='h-[2px] bg-primary w-[380px] my-4'></div>
            </div>
            <div >
              Obtaining renewable energy for multi-story buildings in urban areas is now easier than ever.
            </div>
          </div>
          <div className='flex space-x-2 mt-16'>
            <Button
              onClick={() => {
                const violation: any = document.getElementById("welcome-services-residential-section");
                window.scrollTo({
                  top: violation.offsetTop - 50,
                  behavior: "smooth",
                });
              }}

              className='w-[150px] bg-primary text-white hover:bg-primary hover:text-white'>Learn More</Button>
            <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)} variant={'theamBlack'} className='w-[150px]'>Contact Us</Button>
            <Button onClick={() => {
          window.open(process.env.REACT_APP_BASE_URL_USER_MANUAL)
            }} className='w-[150px] bg-primary text-white hover:bg-primary hover:text-white'>User Manual</Button>
          
          </div>
        </div>
      </div>
    </CarouselItem>
  )
}

export default Banner4